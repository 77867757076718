import { AxiosResponse } from "axios";

export const createApiAxiosResponse = (
  data?: any,
  override?: Partial<AxiosResponse>
): AxiosResponse => ({
  data,
  status: 200,
  statusText: "ok",
  headers: {},
  config: {} as any,
  ...override,
});
