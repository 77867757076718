import { Fixture } from "../features/common/types";
import { randomString } from "../test-utils";
import { User, UserRoles } from "../http/clients/UsersClient";

export const createUser: Fixture<User> = (override) => {
  return {
    id: randomString(),
    email: randomString(),
    role: UserRoles.User,
    terminal: randomString(),
    ...override,
  };
};
