import { HttpClient } from "../HttpClient";
import { AxiosResponse } from "axios";
import { createApiAxiosResponse } from "../../fixtures/Api.fixture";
import { createUser } from "../../fixtures/User.fixture";

export enum UserRoles {
  User = "user",
  Admin = "admin",
}

export type User = {
  id: string;
  email: string;
  terminal: string;
  role: UserRoles;
};

export default class UsersClient extends HttpClient {
  async fetchUsers(): Promise<AxiosResponse<User[]>> {
    return new Promise((resolve) => {
      const user1 = createUser({
        email: "ivan.harshunou@apmterminals.com",
        terminal: "MVII",
      });
      const user2 = createUser({
        email: "indranil.banerjee@maersk.com",
        terminal: "ALG",
      });
      const user3 = createUser({
        email: "janwillem.heining@apmterminals.com",
        role: UserRoles.Admin,
      });
      const user4 = createUser({
        email: "jaroslaw.miazga@apmterminals.com",
        role: UserRoles.Admin,
      });
      setTimeout(() => {
        resolve(createApiAxiosResponse([user1, user2, user3, user4]));
      }, 300);
    });
  }
}
