import React from "react";
import "./Avatar.scss";
import { AdjustableComponent, useClasses } from "../../hooks/useClasses";
import { colorFromText, makeInitials, stripEmojis } from "../../helpers/text";

export type AvatarProps = {
  displayName: string;
  imageSrc?: string;
} & JSX.IntrinsicElements["div"];

export type AvatarStyles = {
  root: string;
};

const Avatar: AdjustableComponent<AvatarProps, AvatarStyles> = ({
  displayName,
  imageSrc,
  classes,
  ...rest
}) => {
  const styles = useClasses(
    {
      root: "Avatar",
    },
    classes
  );
  const initials = makeInitials(stripEmojis(displayName));
  const bg = colorFromText(displayName);
  const inlineStyles = {
    backgroundColor: bg,
  };

  return (
    <div
      className={styles.root}
      title={displayName}
      {...rest}
      style={inlineStyles}
    >
      {!imageSrc && initials}
      {imageSrc && <img src={imageSrc} alt={initials} />}
    </div>
  );
};

export default Avatar;
