import { FunctionComponentWithChildren } from "../types";
import Topbar from "../components/Topbar/Topbar";
import useGetTopbarTitle from "../hooks/useGetTopbarTitle";
import useAuth from "../hooks/useAuth";
import SideNav from "../components/SideNav/SideNav";
import { useSideNavItems } from "../hooks/useSideNavItems";

const AppLayout: FunctionComponentWithChildren = ({ children }) => {
  const getTopbarTitle = useGetTopbarTitle();
  const { user, switchAccount } = useAuth();
  const title = getTopbarTitle();
  const sideNavItems = useSideNavItems();

  return (
    <div className="AppLayout">
      <Topbar title={title} username={user} onLogout={switchAccount} />
      <div className="AppLayout__wrapper">
        <div className="AppLayout__sidebar">
          <SideNav items={sideNavItems} />
        </div>
        <div className="AppLayout__content">{children}</div>
      </div>
    </div>
  );
};

export default AppLayout;
