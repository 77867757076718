import React, { Dispatch, SetStateAction } from "react";
import { McButton } from "@maersk-global/mds-react-wrapper";
import cx from "classnames";
import { useClickOutside } from "../../hooks/useClickOutside";
import { AdjustableComponent, useClasses } from "../../hooks/useClasses";

export type DrawerStyles = {
  root: string;
  wrapper: string;
  title: string;
  content: string;
  footer: string;
};

export type DrawerProps = {
  title: string;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  noFooter?: boolean;
  onRequestClose?: () => void;
};

const Drawer: AdjustableComponent<DrawerProps, DrawerStyles> = ({
  classes,
  title,
  open,
  setOpen,
  onRequestClose,
  noFooter = false,
  children,
}) => {
  const styles = useClasses(
    {
      root: cx("Drawer", {
        "Drawer--open": open,
      }),
      wrapper: "Drawer__wrapper",
      title: "Drawer__title",
      content: "Drawer__content",
      footer: "Drawer__footer",
    },
    classes
  );

  const { ref } = useClickOutside({
    toggler: () => setOpen((p) => !p),
    state: open,
  });

  const onModalClose = () => {
    setOpen(false);
    onRequestClose?.();
  };

  return (
    <div className={styles.root} ref={ref}>
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <h3 className={styles.title}>{title}</h3>
          {children}
        </div>
        {!noFooter && (
          <div className={styles.footer}>
            <McButton
              slot="secondaryAction"
              type="button"
              variant="filled"
              label="Close"
              onClick={() => onModalClose()}
              appearance="primary"
              dialogaction="cancel"
              fit="medium"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Drawer;
