import React from "react";
import useAuth from "./hooks/useAuth";
import { FunctionComponentWithChildren } from "./types";

const ForAuthenticated: FunctionComponentWithChildren = ({ children }) => {
  const { user, unauthorized, switchAccount } = useAuth();

  if (!user) {
    return <div>Loading APMT Port Data...</div>;
  }

  if (user && unauthorized) {
    return (<div>
        Unauthorized, please use a different account or request access.
        <br/>
        <button onClick={switchAccount}>Switch Account</button>
    </div>);
  }

    return <>{children}</>;
};

export default ForAuthenticated;
