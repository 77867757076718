import { useRedirectToRoute } from "./useRedirectToRoute";
import { SideNavItem } from "../components/SideNav/SideNav";
import { useIntl } from "./useIntl";
import { AppRoutes } from "../../../config/routes";

export const useSideNavItems = (): SideNavItem[] => {
  const redirectToRoute = useRedirectToRoute();
  const { formatMessage } = useIntl();

  const items: SideNavItem[] = [
    {
      title: formatMessage({
        id: "sideNavOctw",
      }),
      icon: "vessel-side",
      route: AppRoutes.OctwRules,
      onClick: () => {
        redirectToRoute(AppRoutes.OctwRules);
      },
    },
    {
      title: formatMessage({
        id: "sideNavCapacity",
      }),
      icon: "chart-line-up",
      route: AppRoutes.CapacityId,
      onClick: () => {
        redirectToRoute(AppRoutes.CapacityId, { id: 'ESALR' })
      },
    },
    // {
    //   title: formatMessage({
    //     id: "sideNavUsers",
    //   }),
    //   icon: "people",
    //   route: AppRoutes.Users,
    // },
  ];

  return items;
};
