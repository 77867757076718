import { atom, selector } from "recoil";
import { OctwRule } from "./http/clients/OctwClient";
import { User } from "./http/clients/UsersClient";
import { Maybe } from "./features/common/types";

export const octwRulesAtom = atom<OctwRule[]>({
  key: "octwRulesAtom",
  default: [],
});

export const usersAtom = atom<User[]>({
  key: "usersAtom",
  default: [],
});

export const userDropdownOpenAtom = atom<boolean>({
  key: "userDropdownOpenAtom",
  default: false,
});

export const selectedRuleIdForEditAtom = atom<Maybe<string>>({
  key: "selectedRuleIdForEdit",
  default: undefined,
});

export const selectedRuleForEditSelector = selector<Maybe<OctwRule>>({
  key: "selectedRuleForEditSelector",
  get: ({ get }) => {
    const selectedRuleId = get(selectedRuleIdForEditAtom);
    if (!selectedRuleId) {
      return undefined;
    }
    const rules = get(octwRulesAtom);

    return rules.find((rule) => rule.hubId === selectedRuleId);
  },
});
