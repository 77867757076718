import { EditRuleFormShape } from "../components/EditRuleDrawer/EditRuleDrawer";
import { OctwRule } from "../../../http/clients/OctwClient";
import { Maybe } from "../../common/types";

export type UpdateRuleDto = {
  hubId: string;
  apmtHubId: string;
  hubName: string;
  ruleName: string;
  defaultConnectionTimeWindow: string;
  exceptionalConnectionTimeWindow: string;
};

export const createUpdateRuleDto = (
  formData: EditRuleFormShape,
  baseRule: Maybe<OctwRule>
): UpdateRuleDto => {
  if (!baseRule) {
    throw new Error("Unable to construct dto");
  }
  return {
    apmtHubId: formData.code || "",
    ruleName: formData.ruleName || "",
    hubId: formData.hubId || "",
    defaultConnectionTimeWindow: formData.deOctw?.toString() || "",
    exceptionalConnectionTimeWindow: formData.exOctw?.toString() || "",
    hubName: baseRule.hubName || "",
  };
};
