import { Maybe } from "../features/common/types";
import getPublicClientApplication from "./publicClientApplicationProvider";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { env } from "../../env";

export default class RequestConfigProvider {
  static async getBearerToken(): Promise<Maybe<string>> {
    const publicClientApp = getPublicClientApplication();
    const accounts = publicClientApp.getAllAccounts();

    if (accounts.length > 0) {
      const accessTokenRequest = {
        scopes: [env.REACT_APP_CLIENT_ID + "/read"],
        account: accounts[0],
      };

      return publicClientApp
        .acquireTokenSilent(accessTokenRequest)
        .then((response) => {
          return response.accessToken;
        })
        .catch((error) => {
          console.warn(error);
          if (error instanceof InteractionRequiredAuthError) {
            publicClientApp.acquireTokenRedirect(accessTokenRequest);
          }
          return undefined;
        });
    }
    return undefined;
  }

  static getTimeout(): number {
    return 8000;
  }
}
