import { PublicClientApplication } from "@azure/msal-browser";
import { Maybe } from "../features/common/types";
import { msalConfig } from "../config/auth/authConfig";

let instance: Maybe<PublicClientApplication> = undefined;

const getPublicClientApplication = () => {
  if (!instance) {
    instance = new PublicClientApplication(msalConfig);
  }

  return instance;
};

export default getPublicClientApplication;
