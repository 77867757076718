import React, {Dispatch, SetStateAction} from "react";
import Drawer from "../../../common/components/Drawer/Drawer";
import {
    AdjustableComponent,
    useClasses,
} from "../../../common/hooks/useClasses";
import {ErrorMessage, Field, Form, Formik} from "formik";
import {Maybe} from "../../../common/types";
import FormikInputField from "../../../common/components/FormikInputField/FormikInputField";
import {McButton} from "@maersk-global/mds-react-wrapper";
import z from "zod";
import {toFormikValidationSchema} from "zod-formik-adapter";
import {DependencyContainer} from "../../../../http/DependencyContainer";
import {useSnackbar} from "notistack";
import {useIntl} from "../../../common/hooks/useIntl";
import FormattedMessage from "../../../common/FormattedMessage";
import {createDefaultValueDto, DefaultValueDto} from "../dto/DefaultValueDto";

export type EditDefaultValuesDrawerStyles = {
    field: string;
    buttons: string;
};

export type EditDefaultValuesDrawerProps = {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    fetchRules: () => void,
    selectedTerminal: string,
    defaultValues: Map<string, number>
};

export type EditRuleFormShape = {
    terminalCode: Maybe<string>;
    moves: Maybe<number>,
    yardCapacity: Maybe<number>,
    reeferPlugs: Maybe<number>
};

const FormValidationSchema = z.object({
    terminalCode: z.string(),
    moves: z.number({message: "Value should be a number"}).positive(),
    yardCapacity: z.number({message: "Value should be a number"}).positive(),
    reeferPlugs: z.number({message: "Value should be a number"}).positive(),
});

const {octwService} = new DependencyContainer();

const EditDefaultValuesDrawer: AdjustableComponent<
    EditDefaultValuesDrawerProps,
    EditDefaultValuesDrawerStyles
> = ({classes, open, setOpen, fetchRules, selectedTerminal, defaultValues}) => {
    const styles = useClasses(
        {
            field: "EditDefaultValuesDrawer__field",
            buttons: "EditDefaultValuesDrawer__buttons",
        },
        classes
    );
    const {enqueueSnackbar} = useSnackbar();
    const {formatMessage} = useIntl();

    const onClose = () => {
        setOpen(false);
    };

    const getFormInitialValues = (): EditRuleFormShape => {
        return {
            terminalCode: selectedTerminal,
            moves: defaultValues["moves"],
            yardCapacity: defaultValues["yardCapacity"],
            reeferPlugs: defaultValues["reeferPlugs"]
        };
    };

    const onSubmit = async (values, {setSubmitting}) => {
        setSubmitting(true);
        const dto = createDefaultValueDto(values);
        try {
            await octwService.updateDefaultValues(dto);
            enqueueSnackbar("Default values updated", {
                variant: "success",
            });
            await fetchRules();
            onClose();
        } catch (error) {
            const message = formatMessage({id: "genericErrorMessage"});
            enqueueSnackbar(message, {
                variant: "error",
            });
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Drawer
            title={"Edit default values"}
            open={open}
            setOpen={setOpen}
            onRequestClose={onClose}
            noFooter
        >
            <Formik
                initialValues={getFormInitialValues()}
                enableReinitialize
                validationSchema={toFormikValidationSchema(FormValidationSchema)}
                onSubmit={onSubmit}
            >
                {({isSubmitting}) => (
                    <Form>
                        <div style={{display: "flex", gap: "1rem"}}>
                            <Field
                                label={"Move count"}
                                type="number"
                                name="moves"
                                component={FormikInputField}
                            />
                            <ErrorMessage name="moveCount" component="div"/>
                            <Field
                                label={"Yard capacity"}
                                type="number"
                                name="yardCapacity"
                                component={FormikInputField}
                            />
                            <ErrorMessage name="moveCount" component="div"/>
                            <Field
                                label={"Reefer plugs"}
                                type="number"
                                name="reeferPlugs"
                                component={FormikInputField}
                            />
                            <ErrorMessage name="moveCount" component="div"/>
                        </div>

                        <Field type="hidden" name="hubId"/>
                        <div className={styles.buttons}>
                            <McButton disabled={isSubmitting} type="submit">
                                <FormattedMessage
                                    id={
                                        isSubmitting
                                            ? "updateRuleDrawerFormSubmitting"
                                            : "updateRuleDrawerFormSubmit"
                                    }
                                />
                            </McButton>
                            <McButton
                                type="button"
                                appearance="neutral"
                                click={() => onClose()}
                            >
                                <FormattedMessage id="cancel"/>
                            </McButton>
                        </div>
                    </Form>
                )}
            </Formik>
        </Drawer>
    );
};

export default EditDefaultValuesDrawer;
