import OctwClient from "./clients/OctwClient";
import OctwService from "./services/OctwService";
import UsersService from "./services/UsersService";
import UsersClient from "./clients/UsersClient";

export class DependencyContainer {
  // services
  octwService = new OctwService(this);
  usersService = new UsersService(this);

  //clients
  octwClient = new OctwClient(this);
  usersClient = new UsersClient(this);
}
