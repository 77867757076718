import { McButton, McIcon, McPopover } from "@maersk-global/mds-react-wrapper";
import FormattedMessage from "../../FormattedMessage";
import cx from "classnames";
import { useState } from "react";
import { useClickOutside } from "../../hooks/useClickOutside";
import { useRecoilState } from "recoil";
import { userDropdownOpenAtom } from "../../../../state";
import Avatar from "../Avatar/Avatar";
import { getVersion } from "../../helpers/version";
import { AdjustableComponent, useClasses } from "../../hooks/useClasses";

export type UserDropdownAction = {
  title: string;
  icon: string;
  handler: () => void;
  disabled?: boolean;
};

export type UserDropdownProps = {
  displayName: string;
  userName: string;
  actions?: UserDropdownAction[];
};

export type UserDropdownStyles = {
  root: string;
  window: string;
  header: string;
  avatar: string;
  userDetails: string;
  userActions: string;
  action: string;
  actionIcon: string;
  footer: string;
};

const UserDropdown: AdjustableComponent<
  UserDropdownProps,
  UserDropdownStyles
> = ({ displayName, userName, actions, classes }) => {
  const [isUserDropdownOpen, setIsUserDropdownOpen] =
    useRecoilState(userDropdownOpenAtom);
  const [isOpenLocally, setIsOpenLocally] = useState(isUserDropdownOpen);
  const { ref } = useClickOutside({
    toggler: () => setIsOpen(!isOpenLocally),
    state: isOpenLocally,
  });
  const styles = useClasses(
    {
      root: cx("UserDropdown", {
        "UserDropdown--open": isOpenLocally,
      }),
      window: "UserDropdown__window",
      header: "UserDropdown__header",
      avatar: "UserDropdown__avatar",
      userDetails: "UserDropdown__user-details",
      userActions: "UserDropdown__user-actions",
      action: "UserDropdown__action",
      actionIcon: "UserDropdown__action-icon",
      footer: "UserDropdown__footer",
    },
    classes
  );

  const onActionClick = (action: UserDropdownAction) => {
    if (action.disabled) {
      return;
    }
    action.handler();
    setIsOpen(false);
  };

  const setIsOpen = (isOpen: boolean) => {
    setIsOpenLocally(isOpen);
    setIsUserDropdownOpen(isOpen);
  };

  return (
    <div ref={ref} className={styles.root}>
      <McPopover
        fit="large"
        position="top-center"
        trigger="manual"
        open={isOpenLocally}
        maxwidth="332px"
      >
        <McButton
          slot="trigger"
          hiddenlabel
          click={() => setIsOpen(true)}
          // blur={() => setIsOpen(false)}
          trailingicon={undefined}
          variant="plain"
          appearance="neutral"
          fit="medium"
        >
          <span slot="icon">
            <McIcon icon="user-circle" size="24" />
          </span>
        </McButton>
        <div className={styles.window}>
          <div className={styles.header}>
            <div className={styles.avatar}>
              <Avatar displayName={displayName} />
            </div>
            <div className={styles.userDetails}>
              <span>{displayName}</span>
              <span>{userName}</span>
            </div>
          </div>
          {actions && (
            <div className={styles.userActions}>
              {actions.map((action, index) => (
                <div
                  key={index}
                  className={styles.action}
                  onClick={() => onActionClick(action)}
                >
                  <McIcon className={styles.actionIcon} icon={action.icon} />
                  {action.title}
                </div>
              ))}
            </div>
          )}
          <div className={styles.footer}>
            <strong>
              <FormattedMessage id="user_dropdown_version" />
            </strong>
            <span>
              <FormattedMessage
                id="user_dropdown_version_frontend"
                values={{
                  version: getVersion(),
                }}
              />
            </span>
          </div>
        </div>
      </McPopover>
    </div>
  );
};

export default UserDropdown;
