import { useMsal, useMsalAuthentication } from "@azure/msal-react";
import { AccountInfo, InteractionType, InteractionStatus } from "@azure/msal-browser";
import { useEffect, useState } from "react";
import { Maybe } from "../types";
import { env } from "../../../../env";

type UseAuthValues = {
    user: Maybe<string>;
    logout: () => void;
    token: Maybe<string>;
    unauthorized: boolean;
    isAdmin: boolean;
    switchAccount: () => void;
};

const ADMIN_ROLE_NAME = "apmt-portdata-admin";
const USER_ROLE_NAME = "apmt-portdata-user";

const useAuth = (): UseAuthValues => {
    useMsalAuthentication(InteractionType.Redirect);
    const { instance, inProgress } = useMsal();
    const [unauthorized, setUnauthorized] = useState<boolean>(false);
    const [user, setUser] = useState<string>();
    const [token, setToken] = useState<string>();
    const [account, setAccount] = useState<AccountInfo | null>(null);
    const [isAdmin, setIsAdmin] = useState<boolean>(false);

    const switchAccount = async () => {
        try {
            await instance.acquireTokenRedirect({
                scopes: ["user.read"],
                prompt: "select_account",
            });
        } catch (error) {
            console.log("Error during account switch: ", error);
        }
    };

    const logout = async () => {
        if (!account) {
            return;
        }
        const logoutRequest = {
            account: account,
            postLogoutRedirectUri: env.REACT_APP_REDIRECT_URL + "/",
        };
        await instance.logoutRedirect(logoutRequest);
        sessionStorage.clear();
    };

    useEffect(() => {
        const initializeInstance = async () => {
            console.log("INSTANCE DEPENDENCY CHANGED");
            const response = await instance.handleRedirectPromise();
            if (response) {
                console.log("Redirect response received:", response);
                setAccount(response.account);
            } else {
                const activeAccount = instance.getActiveAccount();
                console.log("Active account: " + JSON.stringify(activeAccount));
                if (activeAccount) {
                    setAccount(activeAccount);
                } else {
                    if (inProgress === InteractionStatus.None) {
                        switchAccount();
                    } else {
                        console.log("Interaction already in progress");
                    }
                }
            }
        };

        if (inProgress === InteractionStatus.None) {
            initializeInstance();
        }
    }, [instance, inProgress]);

    useEffect(() => {
        console.log("Account updated, acc" + JSON.stringify(account));
        if (account) {
            try {
                const username = account.username;
                const token = account.idToken;
                const roles = account.idTokenClaims?.roles;
                setUser(username);
                setToken(token);
                const isCurrentUserMember = roles?.includes(USER_ROLE_NAME) || false;
                const isCurrentUserAdmin = roles?.includes(ADMIN_ROLE_NAME) || false;
                setIsAdmin(isCurrentUserAdmin);
                setUnauthorized(!isCurrentUserMember && !isCurrentUserAdmin);
                console.log("Current roles: " + roles);
            } catch (error) {
                console.log("Error retrieving user data: ", error);
            }
        }
    }, [account]);

    return { user, token, unauthorized, isAdmin, logout, switchAccount };
};

export default useAuth;
