import { OctwRule } from "../../../../http/clients/OctwClient";
import { Maybe } from "../../../common/types";
import {EditRuleFormShape} from "../EditDefaultValuesDrawer/EditDefaultValuesDrawer";

export type DefaultValueDto = {
    moves: Maybe<number>;
    yardCapacity: Maybe<number>;
    reeferPlugs: Maybe<number>;
    terminalCode: Maybe<string>;
};

export const createDefaultValueDto = (
  formData: EditRuleFormShape,
): DefaultValueDto => {
  return {
    moves: formData.moves,
    yardCapacity: formData.yardCapacity,
    reeferPlugs: formData.reeferPlugs,
    terminalCode: formData.terminalCode,
  };
};
