import { DependencyContainer } from "../DependencyContainer";
import { OctwRule } from "../clients/OctwClient";
import { User } from "../clients/UsersClient";

export default class UsersService {
  constructor(private readonly factory: DependencyContainer) {}

  async getUsers(): Promise<User[]> {
    const response = await this.factory.usersClient.fetchUsers();
    const results = response.data;

    return results;
  }
}
