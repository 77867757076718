import React from "react";
import { AdjustableComponent, useClasses } from "../../hooks/useClasses";
import { McTooltip } from "@maersk-global/mds-react-wrapper";
import cx from "classnames";

export type PropertyProps = {
  label: string;
  value: string;
  tooltip?: {
    title: string;
    content: string;
  };
};

export type PropertyStyles = {
  root: string;
  label: string;
  value: string;
};

const Property: AdjustableComponent<PropertyProps, PropertyStyles> = ({
  label,
  value,
  tooltip,
  classes,
}) => {
  const styles = useClasses(
    {
      root: "Property",
      label: cx("Property__label", {
        "Property__label--tooltip": Boolean(tooltip),
      }),
      value: "Property__value",
    },
    classes
  );

  const getLabel = () => {
    const labelContent = <div className={styles.label}>{label}</div>;
    if (tooltip) {
      return (
        <McTooltip>
          <span slot="trigger">{labelContent}</span>
          <span>
            <strong>{tooltip.title}</strong>
            <br />
            {tooltip.content}
          </span>
        </McTooltip>
      );
    }
    return labelContent;
  };

  return (
    <div className={styles.root}>
      {getLabel()}
      <div className={styles.value}>{value}</div>
    </div>
  );
};

export default Property;
