import {OctwRule} from "../../../../http/clients/OctwClient";
import {Maybe} from "../../../common/types";
import {EditRuleFormShape} from "../EditWeeklyValuesDrawer/EditWeeklyValuesDrawer";

export type WeeklyValueDto = {
    moves: Maybe<number>;
    yardCapacity: Maybe<number>;
    reeferPlugs: Maybe<number>;
    terminalCode: Maybe<string>;
    weeks: Maybe<string[]>
};

export const createWeeklyValueDto = (
    formData: EditRuleFormShape,
): WeeklyValueDto => {
    return {
        moves: formData.moves,
        yardCapacity: formData.yardCapacity,
        reeferPlugs: formData.reeferPlugs,
        terminalCode: formData.terminalCode,
        weeks: formData.selectedWeeks
    };
};
