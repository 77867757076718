export const stripEmojis = (input: string) =>
  input
    .replace(
      new RegExp(
        /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g,
        "g"
      ),
      ""
    )
    .trim();

export const makeInitials = (displayName: string) => {
  const initials = displayName.split(" ").map((n) => n[0]);
  const [a, b, c] = initials;
  return [a, b, c].join("").toUpperCase();
};

export const makeTextExcerpt = (
  text: string,
  length: number,
  suffix: string = "…"
) => (text.length > length ? text.substring(0, length) + suffix : text);

const avatarColors = [
  "#009a4a",
  "#11ade0",
  "#B381B3",
  "#939393",
  "#E3BC00",
  "#D47500",
  "#d52727",
];

export const colorFromText = (text: string) => {
  const charCodes = text
    .split("")
    .map((char) => char.charCodeAt(0))
    .join("");
  const number = parseInt(charCodes, 10);
  return avatarColors[number % avatarColors.length];
};
