import React, {useEffect, useRef, useState} from "react";
import {useLocation, useParams} from "react-router-dom";
import {useNavigate} from 'react-router-dom';
import {useRedirectToRoute} from "../../common/hooks/useRedirectToRoute";
import {AppRoutes} from "../../../config/routes";
import {FormattedMessage, useIntl} from "react-intl";
import {
    McLoadingIndicator,
    McNotification,
    McTable,
    McOption,
    McButton,
    McSelect
} from "@maersk-global/mds-react-wrapper";
import {TableColumn} from "@maersk-global/mds-components-core/mc-table/types";
import {DependencyContainer} from "../../../http/DependencyContainer";
import EditRuleDrawer from "../../octw/components/EditRuleDrawer/EditRuleDrawer";
import EditDefaultValuesDrawer from "./EditDefaultValuesDrawer/EditDefaultValuesDrawer";
import EditWeeklyValuesDrawer from "./EditWeeklyValuesDrawer/EditWeeklyValuesDrawer";
import SelectedWeeksHeader from "./SelectedWeeksHeader/SelectedWeeksHeader";

const {octwService} = new DependencyContainer();

interface Terminal {
    name: string;
    code: string;
}

const terminals: Terminal[] = [
    {name: "Algeciras", code: "ESALR"},
    {name: "Rotterdam  Maasvlakte II", code: "NLROT"},
    {name: "Tanjung Pelepas", code: "MYTPP"},
    {name: "Suez Canal Container Terminal", code: "EGPSD"},
    {name: "Salalah", code: "OMSLV"},
    {name: "Tangier Med - TC1", code: "MAPTMTM"},
    {name: "Tangier Med - TM2", code: "MAPTM02"},
];

// @ts-ignore
const CapacityIdContainer: React.FC = () => {
    const navigate = useNavigate(); // Initialize navigate
    const defaultTableRef = useRef(null);
    const weeklyTableRef = useRef(null);
    const location = useLocation(); // Get the current location
    const {id} = useParams<{ id: string }>();
    const [selectedTerminal, setSelectedTerminal] = useState(id || "");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [apiResponse, setApiResponse] = useState(null);
    const [defaultData, setDefaultData] = useState([]);
    const [weeklyData, setWeeklyData] = useState([]);
    const [selectedDefaultRow, setSelectedDefaultRow] = useState(null);
    const [selectedWeeklyData, setSelectedWeeklyData] = useState(null);
    const [selectedWeeklyRow, setSelectedWeeklyRow] = useState([]);

    const [selectedWeeklyRows, setSelectedWeeklyRows] = useState([]);

    const {formatMessage} = useIntl();
    const redirectToRoute = useRedirectToRoute();

    const handleSelectChange = async (selectedCode) => {
        if (!selectedCode) return
        setSelectedTerminal(selectedCode);
        setLoading(true);
        setError(null);

        try {
            navigate(`/capacity/${selectedCode}`);
            await redirectToRoute(AppRoutes.CapacityId, {id: selectedCode});
            fetchCapacityData(selectedCode);
        } catch (err) {
            setError(formatMessage({id: "genericErrorMessage"}));
            setLoading(false);
        }
    };

    const fetchCapacityData = async (terminalCode: string) => {
        setLoading(true);
        setError(null);

        try {
            // Make a single API call to get both capacityEntities and capacityTemplateEntity
            const response = await octwService.getCapacityForTerminal(terminalCode);

            // Log the response to inspect it
            console.log("API Response:", response);

            // Destructure directly from response, not response.data
            const {capacityEntities, capacityTemplateEntity} = response;

            setApiResponse(response)
            if (capacityTemplateEntity) {
                // Update defaultData based on the fetched capacityTemplateEntity
                // @ts-ignore
                setDefaultData([{
                    moveCount: `${capacityTemplateEntity.moves} moves`,
                    yardCapacity: `${capacityTemplateEntity.yardCapacity} TEU`,
                    reeferPlugs: `${capacityTemplateEntity.reeferPlugs} plugs`,
                }]);
            } else {
                console.warn("capacityTemplateEntity is missing in the response data");
                setDefaultData([]);
            }

            if (Array.isArray(capacityEntities) && capacityEntities.length > 0) {
                // Update weeklyData based on the fetched capacityEntities
                const weekly = capacityEntities.map((week, index) => ({
                    date: `${week.date}`,  // Adjust this if you need to use actual dates
                    moveCount: `${week.moves} moves`,
                    yardCapacity: `${week.yardCapacity} TEU`,
                    reeferPlugs: `${week.reeferPlugs} plugs`,
                    status: week.overwrite ? 'Overwritten' : 'Default',
                }));
                // @ts-ignore
                setWeeklyData(weekly);
            } else {
                console.warn("capacityEntities is missing or empty in the response data");
                setWeeklyData([]);
            }

        } catch (err) {
            console.error("Error fetching capacity data:", err); // Log the error object for debugging
            setError(formatMessage({id: "genericErrorMessage"}));
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (selectedTerminal) {
            fetchCapacityData(selectedTerminal);
        }
    }, [selectedTerminal]);

    const defaultColumns: TableColumn[] = [
        {id: 'moveCount', label: 'Move count', align: 'left'},
        {id: 'yardCapacity', label: 'Yard Capacity', align: 'left'},
        {id: 'reeferPlugs', label: 'Reefer plugs', align: 'left'},
    ];

    const weeklyColumns: TableColumn[] = [
        {id: 'date', label: 'Date', align: 'left'},
        {id: 'moveCount', label: 'Move count', align: 'left'},
        {id: 'yardCapacity', label: 'Yard Capacity', align: 'left'},
        {id: 'reeferPlugs', label: 'Reefer plugs', align: 'left'},
        {id: 'status', label: 'Status', align: 'left'},
    ];

    // Function to handle row click in the default table
    const onDefaultRowClick = (e) => {
        const row = e.detail.rowData;
        setSelectedDefaultRow(row); // Set the selected row
    };
    const onWeeklySelectChange = (e) => {
        setSelectedWeeklyRows(e.detail)
    }
    const onWeeklyRowClick = (e) => {
        const row = e.detail.rowData;
        console.log("Selected weekly row:", row);

        // Check if multiple rows selected, if all numbers same then set it, else set all to 0 0 0
        setSelectedWeeklyData({
            // @ts-ignore
            moves: parseInt(row.moveCount.replace(" moves", "")),
            yardCapacity: parseInt(row.yardCapacity.replace(" TEU", "")),
            reeferPlugs: parseInt(row.reeferPlugs.replace(" plugs", ""))
        })

        // @ts-ignore
        setSelectedWeeklyRow([row.date]);
    };

    // @ts-ignore
    return (
        <div className="CapacityContainer">
            {/* EditWeeklyValuesDrawer controlled by selectedDefaultRow */}
            {apiResponse && <EditDefaultValuesDrawer
                // @ts-ignore
                defaultValues={apiResponse.capacityTemplateEntity}
                selectedTerminal={selectedTerminal}
                open={Boolean(selectedDefaultRow)}
                fetchRules={() => fetchCapacityData(selectedTerminal)}  // Assuming this is needed
                setOpen={() => setSelectedDefaultRow(null)} // Close drawer
            />}

            {apiResponse && selectedWeeklyData && <EditWeeklyValuesDrawer
                // @ts-ignore
                defaultValues={selectedWeeklyData}
                selectedTerminal={selectedTerminal}
                open={selectedWeeklyRow.length > 0}
                selectedWeeks={selectedWeeklyRow}
                fetchRules={() => {
                    setSelectedWeeklyRows([])
                    fetchCapacityData(selectedTerminal)
                }}  // Assuming this is needed
                setOpen={() => setSelectedWeeklyRow([])} // Close drawer
            />}

            <McSelect
                label={"Select terminal"}
                placeholder="Select terminal"
                value={selectedTerminal}
                optionselected={(e) => {
                    // @ts-ignore
                    handleSelectChange(e.target.value)
                }}
                name="terminal">
                {terminals.map((terminal) => (
                    <McOption key={terminal.code} value={terminal.code}>
                        {terminal.name}
                    </McOption>
                ))}
            </McSelect>

            {error && (
                <McNotification
                    appearance="error"
                    heading={formatMessage({id: "error"})}
                >
                    <FormattedMessage id="genericErrorMessage"/>
                </McNotification>
            )}

            {loading ? (
                <McLoadingIndicator label={formatMessage({id: "fetchingData"})}/>
            ) : (
                selectedTerminal && (
                    <>
                        <div>
                            <h3>Default values</h3>
                            <p>Some explanatory text.</p>
                            <McTable
                                ref={defaultTableRef}
                                columns={defaultColumns}
                                data={defaultData}
                                rowclick={onDefaultRowClick} // Handle row click
                            />
                        </div>
                        <div>
                            <h3>Weekly values</h3>
                            <p>Some explanatory text.</p>
                            {selectedWeeklyRows.length > 0 &&
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    padding: '0.75rem 1rem',
                                    backgroundColor: '#FAE5DA', // Light background color
                                    borderRadius: '4px',
                                    marginBottom: '1rem',
                                    border: '1px solid #FF7A59' // Border color matching the button
                                }}>
                                <span style={{fontSize: '1rem', fontWeight: 'bold', color: '#333'}}>
                                    {selectedWeeklyRows.length} week(s) selected
                                </span>
                                    <McButton
                                        label="Edit"
                                        appearance="secondary"
                                        variant="outlined"
                                        padding="compact"
                                        click={() => {
                                            setSelectedWeeklyData({
                                                // @ts-ignore
                                                moves: parseInt(selectedWeeklyRows[0].moveCount.replace(" moves", "")),
                                                // @ts-ignore
                                                yardCapacity: parseInt(selectedWeeklyRows[0].yardCapacity.replace(" TEU", "")),
                                                // @ts-ignore
                                                reeferPlugs: parseInt(selectedWeeklyRows[0].reeferPlugs.replace(" plugs", ""))
                                            })
                                            // @ts-ignore
                                            console.log("Selected weekly rows:", selectedWeeklyRows.map(row => row.date));
                                            setSelectedWeeklyRow(
                                                // @ts-ignore
                                                selectedWeeklyRows.map(row => row.date))
                                        }}
                                    />
                                </div>
                            }
                            <McTable
                                // @ts-ignore
                                rowclick={onWeeklyRowClick}
                                selectchange={onWeeklySelectChange}
                                select={true}
                                ref={weeklyTableRef}
                                columns={weeklyColumns}
                                data={weeklyData}
                            />
                        </div>
                    </>
                )
            )}
        </div>
    );
};

export default CapacityIdContainer;
