import React from "react";
import { AdjustableComponent, useClasses } from "../../hooks/useClasses";
import UserDropdown, { UserDropdownAction } from "../UserDropdown/UserDropdown";
import { useIntl } from "react-intl";

export type TopbarProps = {
  onLogout: () => void;
  username?: string;
  title?: string;
};

export type TopbarStyles = {
  root: string;
  title: string;
  userDropdown: string;
};

const Topbar: AdjustableComponent<TopbarProps, TopbarStyles> = ({
  onLogout,
  username,
  title,
  classes,
}) => {
  const styles = useClasses(
    {
      root: "Topbar",
      title: "Topbar__title",
      userDropdown: "Topbar__user-dropdown",
    },
    classes
  );
  const { formatMessage } = useIntl();

  const userDropdownActions: UserDropdownAction[] = [
    {
      title: formatMessage({ id: "user_dropdown_logout" }),
      icon: "door-arrow-right",
      handler: onLogout,
    },
  ];

  return (
    <div className={styles.root}>
      <div className={styles.title}>{title}</div>
      <div className={styles.userDropdown}>
        <UserDropdown
          classes={(current) => ({
            ...current,
            root: `${current.root} Topbar__user-dropdown-button`,
          })}
          displayName={username || ""}
          userName={username || ""}
          actions={userDropdownActions}
        />
      </div>
    </div>
  );
};

export default Topbar;
