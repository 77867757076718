import React from "react";
import { FormattedMessage as ReactIntlFormattedMessage } from "react-intl";
import { Translation } from "./types";

type ReactIntlFormattedMessageProps = React.ComponentProps<
  typeof ReactIntlFormattedMessage
>;
type TypedFormattedMessageProps = Omit<ReactIntlFormattedMessageProps, "id"> & {
  id: keyof Translation;
};

export const FormattedMessage: React.FunctionComponent<
  TypedFormattedMessageProps
> = (props) => <ReactIntlFormattedMessage {...props} />;

export default FormattedMessage;
