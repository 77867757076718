import React, { FunctionComponent } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { octwRulesAtom, selectedRuleIdForEditAtom } from "../../../state";
import { McTable } from "@maersk-global/mds-react-wrapper";
import type { TableColumn } from "@maersk-global/mds-components-core/mc-table/types";
import { useIntl } from "../../common/hooks/useIntl";
import EditRuleDrawer from "./EditRuleDrawer/EditRuleDrawer";

export type OctwAdminViewProps = {
  fetchRules: () => void;
};

const OctwAdminView: FunctionComponent<OctwAdminViewProps> = ({
  fetchRules,
}) => {
  const rules = useRecoilValue(octwRulesAtom);
  const { formatMessage } = useIntl();
  const [selectedRuleIdForEdit, setSelectedRuleIdForEdit] = useRecoilState(
    selectedRuleIdForEditAtom
  );

  const columns: Array<TableColumn> = [
    {
      id: "terminal",
      label: formatMessage({ id: "octwAdminTableColumnTerminal" }),
    },
    {
      id: "code",
      label: formatMessage({ id: "octwAdminTableColumnCode" }),
      tabularFigures: true,
      align: "right",
    },
    {
      id: "ruleName",
      label: formatMessage({ id: "octwAdminTableColumnRuleName" }),
    },
    {
      id: "deOtw",
      align: "right",
      label: formatMessage({ id: "defaultOctwAdminTableColumnOtw" }),
    },
    {
      id: "exOtw",
      align: "right",
      label: formatMessage({ id: "exceptionalOctwAdminTableColumnOtw" }),
    },
  ];

  const data = rules.map((rule) => ({
    terminal: rule.hubName,
    code: rule.apmtHubId,
    hubId: rule.hubId,
    ruleName: rule.ruleName,
    deOtw: `${rule.deOperationalConnectionTimeWindow} min`,
    exOtw: `${rule.exOperationalConnectionTimeWindow} min`,
  }));

  const onRowClick = (e) => {
    const row = e.detail.rowData;
    // Currently API is built using hubId as primary key. It's supposed to be unique but when it becomes duplicated, let's rethink this allocation
    setSelectedRuleIdForEdit(row.hubId);
  };

  return (
    <div className="RulesContainer__admin-view">
      <McTable rowclick={onRowClick} columns={columns} data={data} />
      <EditRuleDrawer
        open={Boolean(selectedRuleIdForEdit)}
        fetchRules={fetchRules}
        setOpen={() => setSelectedRuleIdForEdit(undefined)}
      />
    </div>
  );
};

export default OctwAdminView;
